@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-10px);
  }

  60% {
    transform: translateY(-5px);
  }
}

/* Add this class to your component */
.animate-bounce {
  animation: bounce 0.8s ease-in-out;
}


.fade-in-section {
  opacity: 0;
  transform: translateY(10%);
  transition: opacity 0.75s ease-in-out, transform 0.75s ease-in-out;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: translateY(0);
}

.hitable {
  cursor: pointer;
  transition: background-color 0.2s ease-out, transform 0.1s linear;
}

.hitable.hit {
  background-color: rgba(255, 0, 0, 0.5);
  transform: translateX(-2px);
  animation: shake 0.1s ease-in-out infinite;
}

@keyframes shake {

  0%,
  100% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(2px);
  }
}

.hitable {
  cursor: pointer;
  transition: background-color 0.2s ease-out, transform 0.1s linear;
  border-radius: 15px;
  /* Adjust the border-radius according to your design */
}

.hitable.hit {
  background-color: rgba(255, 0, 0, 0.5);
  transform: translateX(-2px);
  animation: shake 0.1s ease-in-out infinite;
}

@keyframes shake {

  0%,
  100% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(2px);
  }
}