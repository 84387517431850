body {
    margin: 0;
    font-family: acumin-variable, sans-serif;
    font-style: bold;
    font-variation-settings: "slnt" 0, "wdth" 100, "wght" 194.2857;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

